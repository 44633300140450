import styled from "styled-components"

import { col, containerSideOffset, SectionTitleMain } from "../common.styles"

export const OurOfficesMain = styled.div`
padding: 140px 0 80px;

${props => props.theme.max('md', () => (`
  padding: 90px 0 100px;
`))}


.list-container {
  align-items: center;
  padding: 50px 0;
  
  ${props => props.theme.max('sm', () => (`
    padding-top: 0;
  `))}

  ${SectionTitleMain} {
    margin-bottom: .75em;

    ${props => props.theme.max('sm', () => (`
      margin-bottom: .45em;
    `))}
  }
}

.has-secondary {
  ${props => props.theme.min('sm', () => (`
    padding-bottom: 0;
  `))}
  
  ${SectionTitleMain} {
    margin-bottom: .45em;
  }
  
  .primary-address-link {
    margin-bottom: 2em;
  }
  
  .list-right {
    padding-bottom: 40px;
  }
}



.title-col {
  ${containerSideOffset()}
  ${col(24 - 8, 'c')}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

.list-left {
  ${containerSideOffset()}
  ${col(35)}
  padding-top: 12px;

  ${props => props.theme.max('md', () => (`
    ${col(45)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    order: 2;
    padding-top: 19px;
  `))}
}

.list-right {
  margin-left: auto;
  ${containerSideOffset('right')}
  ${col(29.15)}

  ${props => props.theme.max('md', () => (`
    ${col(45)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    order: 1;
  `))}
}

.country {
  font-size: 22px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 13px;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 39px;
  `))}
}

ul {
  margin: 0 0 53px;
  padding: 0;
  list-style: none;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 31px;
  `))}
}

li {
  font-size: 22px;
  line-height: 1.63;

  ${props => props.theme.max('sm', () => (`
    font-size: 16px;
  `))}
}

${SectionTitleMain} {
  margin-bottom: 1.25em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 1em;
  `))}
}

.to-animate {
  &.list-container ul, 
  .country {
    transform: translate(0, 50px);
    opacity: 0;
  }
}

.to-animate:not(.is-in-vp) {
  &.list-container ul, 
  .country {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp {
  &.list-container ul, 
  .country {
    transition: ${800}ms ease ${400}ms;
    transform: translate(0);
    opacity: 1;
  }
}

.modifier-class {
  outline-style: none;
}

`
