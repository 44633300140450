import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { OurOffices } from "../components/contact/our-offices-section"
import { ContactInformation } from "../components/contact/contact-information-section"
import { processHexagonData } from "../helpers/common"
import { BannerContact } from "../components/banner-contact"


const ContactTemplate = ({
  data: {
    wpgraphql: {
      page: {
        title,
        contactacf
      },
      themeSettings: {
        themesettingsacf: contactDetails
      }
    },
  }
}) => {
  const hexagonData = processHexagonData([
    contactacf.banner.name,
    contactacf.offices.name,
    contactacf.contactSectionName,
  ]);

  console.log(contactacf,'tseg')
  return (
    <Layout>
      <SEO title={title} />

      <BannerContact
        hexagonData={hexagonData[0]}
        title={contactacf.banner.title}
        linkData={contactacf.banner.acfLink}
        backgroundMobile={contactacf.banner.imageMobile}
        modifier="contact-page"
        linkClass={"contact"}
      />

      <OurOffices
        directionsButtonText={contactacf.offices.directionsButtonText}
        hexagonData={hexagonData[1]}
        title={contactacf.offices.title}
        items={contactacf.offices.items}
      />

      <ContactInformation
        hexagonData={hexagonData[2]}
        firstTitle={contactacf.contactSectionTitle}
        contactDetails={contactDetails}
      />

    </Layout>
  )
}

export default ContactTemplate

export const homeTemplateQuery = graphql`
  query ContactQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        contactacf {
          banner {
            name
            title
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
          offices {
            items {
              city
              country
              link
              address {
                text
              }
              linkSecondary
              hasSecondaryAddress
              addressSecondary {
                text
              }
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            directionsButtonText
            name
            title
            
          }
          contactSectionName
          contactSectionTitle
        }
      }

      themeSettings {
        themesettingsacf {
          contactIrPhoneLink
          contactIrPhoneText
          contactIrPhoneLinkUsa
          contactIrPhoneTextUsa
          contactIrEmail
          contactIrLabel
          otherEnquiriesEmail
          otherEnquiriesLabel
          otherEnquiriesPhoneLink
          otherEnquiriesPhoneText
          pressEnquiriesGroupName
          pressEnquiriesLabel
          pressEnquiriesUkEmail
          pressEnquiriesUkEmailText
          pressEnquiriesUkPhoneLink
          pressEnquiriesUsaEmail
          pressEnquiriesUkPhoneText
          pressEnquiriesUsaEmailText
          pressEnquiriesUsaPhoneLink
          pressEnquiriesUsaPhoneText
          siteSocialLabel
          siteSocialLinkedinLink
          siteSocialLinkedinLinkText
        }
      }
    }

  }
`
