import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { BtnInner } from "../../elements/btn-inner"
import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { RevealImage } from "../../elements/reveal-image"
import { seoClassPrefix, attribufyString } from "../../helpers/common"

import { OurOfficesMain } from "../../styles/contact/our-offices.styles"

const OfficeItem = (props) => {
  const { country, city, address, image, link, linkText, addressSecondary, hasSecondaryAddress, linkSecondary } = props;

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
      {({ isVisible }) =>
        <div className={`list-container ${hasSecondaryAddress ? 'has-secondary' : ''} flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="list-left">
            <p className="country">{country}</p>
            <SectionTitle text={city} variation="small-title" />
            <ul>
              {(address || []).map(({ text }, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
            <a href={link} target="_blank" rel="noreferrer" className={`btn primary-address-link secondary ${seoClassPrefix}single-office-link office-${attribufyString(city)}-link modifier-class`}>
              <BtnInner text={linkText} />
            </a>

            {hasSecondaryAddress && (
              <ul>
                {(addressSecondary || []).map(({ text }, index) => (
                  <li key={`s${index}`}>{text}</li>
                ))}
              </ul>
            )}

            {hasSecondaryAddress && (
              <a href={linkSecondary} target="_blank" rel="noreferrer" className={`btn secondary secondary-address-link ${seoClassPrefix}-sec-single-office-link office-${attribufyString(city)}-link modifier-class`}>
                <BtnInner text={linkText} />
              </a>
            )}
          </div>

          <div className="list-right">
            <RevealImage image={image} variation="from-right" />
          </div>
        </div>
      }
    </VisibilitySensor>
  )
}

export const OurOffices = ({
  hexagonData: { name, sectionNum, },
  title,
  items,
  directionsButtonText,
}) => (
  <OurOfficesMain>
    <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
      {({ isVisible }) =>
        <div className={`flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="title-col">
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>
            <SectionTitle text={title} />
          </div>
        </div>
      }
    </VisibilitySensor>

    {items.map((office, index) => (
      <OfficeItem {...office} linkText={directionsButtonText} key={index} />
    ))}

  </OurOfficesMain>
)

